import Head from 'next/head'

export default function Home () {
  return (
    <div>
      <Head>
        <title>Studio Low cost</title>
        <meta name='description' content='' />
        <link rel='icon' href='/favicon.ico' />
      </Head>

      <main>
        <h1>
          Welcome to <a href='#'>App</a>
        </h1>
      </main>
    </div>
  )
}
